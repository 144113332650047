// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global variables */
:root {
    --header-height: 90px;
    --sidebar-width: 200px;
    --footer-height: 60px;
    --footer-text-color: #000;
    --footer-text-size: 13px;
    --background-colour: rgb(255, 255, 255);
    --header-colour:rgb(232, 232, 232);
    --sidebar-colour: rgb(232, 232, 232);
    --mobile-menu-colour: rgb(0, 0, 0);
  }



.card {
  width: 70vw;
  /* height: 100%; */
  border-radius: 30px;
  background: #ffffff;
  /* box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff; */
  padding: 1.2em;
  /* margin: 4rem 0; */
  
}


h1, h2, h3, h4, h5, span, p{
    font-family: "poppins";
    line-height: 2.2ch;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.6em;
    }
    .card{
      width: 80vw;
    }
  }`, "",{"version":3,"sources":["webpack://./src/globalStyles.css"],"names":[],"mappings":"AACA,qBAAqB;AACrB;IACI,qBAAqB;IACrB,sBAAsB;IACtB,qBAAqB;IACrB,yBAAyB;IACzB,wBAAwB;IACxB,uCAAuC;IACvC,kCAAkC;IAClC,oCAAoC;IACpC,kCAAkC;EACpC;;;;AAIF;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,kEAAkE;EAClE,cAAc;EACd,oBAAoB;;AAEtB;;;AAGA;IACI,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE;MACE,gBAAgB;IAClB;IACA;MACE,WAAW;IACb;EACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap\");\n/* global variables */\n:root {\n    --header-height: 90px;\n    --sidebar-width: 200px;\n    --footer-height: 60px;\n    --footer-text-color: #000;\n    --footer-text-size: 13px;\n    --background-colour: rgb(255, 255, 255);\n    --header-colour:rgb(232, 232, 232);\n    --sidebar-colour: rgb(232, 232, 232);\n    --mobile-menu-colour: rgb(0, 0, 0);\n  }\n\n\n\n.card {\n  width: 70vw;\n  /* height: 100%; */\n  border-radius: 30px;\n  background: #ffffff;\n  /* box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff; */\n  padding: 1.2em;\n  /* margin: 4rem 0; */\n  \n}\n\n\nh1, h2, h3, h4, h5, span, p{\n    font-family: \"poppins\";\n    line-height: 2.2ch;\n  }\n\n  @media (max-width: 768px) {\n    h1 {\n      font-size: 1.6em;\n    }\n    .card{\n      width: 80vw;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
