// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button {
  height: 40px;
  background: #F2F2F2;
  border-radius: 11px;
  border: 0;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(180deg, #363636 0%, #1B1B1B 50%, #000000 100%);
  box-shadow: 0px 0px 0px 0px #FFFFFF, 0px 0px 0px 0px #000000;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.primary-button:hover {
  box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #0000003a;
}

.primary-button:disabled,
.primary-button[disabled]{
  background: #3a3a3a;
  color: #666666;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/primary/Button.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,0EAA0E;EAC1E,4DAA4D;EAC5D,sDAAsD;AACxD;;AAEA;EACE,8DAA8D;AAChE;;AAEA;;EAEE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".primary-button {\n  height: 40px;\n  background: #F2F2F2;\n  border-radius: 11px;\n  border: 0;\n  outline: none;\n  color: #ffffff;\n  font-size: 14px;\n  font-weight: 700;\n  background: linear-gradient(180deg, #363636 0%, #1B1B1B 50%, #000000 100%);\n  box-shadow: 0px 0px 0px 0px #FFFFFF, 0px 0px 0px 0px #000000;\n  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);\n}\n\n.primary-button:hover {\n  box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #0000003a;\n}\n\n.primary-button:disabled,\n.primary-button[disabled]{\n  background: #3a3a3a;\n  color: #666666;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
