// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  margin: Calc(1.2 * var(--header-height)) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90vw;
}

.loading-container h2 {
  animation: typing 1s steps(10, end) forwards;
  /* animation: typing 1s forwards; */
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
  width: 0;
  max-width: fit-content;
}

.loading-spinner {
  border: 3px solid;
  height: 30px;
  border-color: transparent black transparent #02adb5;
  width: 30px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  animation: spinner 2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,4CAA4C;EAC5C,mCAAmC;EACnC,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,sBAAsB;EACtB,cAAc;EACd,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,mDAAmD;EACnD,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE;EACF;;EAEA;IACE;EACF;AACF","sourcesContent":[".loading-container {\n  margin: Calc(1.2 * var(--header-height)) 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 90vw;\n}\n\n.loading-container h2 {\n  animation: typing 1s steps(10, end) forwards;\n  /* animation: typing 1s forwards; */\n  white-space: nowrap;\n  overflow: hidden;\n  border-right: 3px solid;\n  font-family: monospace;\n  font-size: 2em;\n  width: 0;\n  max-width: fit-content;\n}\n\n.loading-spinner {\n  border: 3px solid;\n  height: 30px;\n  border-color: transparent black transparent #02adb5;\n  width: 30px;\n  border-radius: 50%;\n  margin-left: auto;\n  margin-right: auto;\n  animation: spinner 2s linear infinite;\n}\n\n@keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes typing {\n  from {\n    width: 0\n  }\n\n  to {\n    width: 100%\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
