// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer{
    position: fixed;
    width: Calc(100vw - var(--sidebar-width));
    height: var(--footer-height);
    bottom: 0;
    right: 0;
    z-index: 9;
    background-color: var(--background-colour);
    box-shadow: 0px -2px 18px 2px rgba(0, 0, 0, 0.041);
    color: var(--footer-text-color);
}
.footer-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
    font-size: var(--footer-text-size);
    gap: 0.3em;
}

.footer-buttons{
    display: none;
}

/* Mobile breakpoints */

@media (max-width: 768px) {
   footer{
    width: 100vw!important;
   }
    .footer-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 50%;
        gap: 0.3em;
      }
      .footer-content{
        font-size: 8px;
        color: rgba(0, 0, 0, 0.216);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/layout/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yCAAyC;IACzC,4BAA4B;IAC5B,SAAS;IACT,QAAQ;IACR,UAAU;IACV,0CAA0C;IAC1C,kDAAkD;IAClD,+BAA+B;AACnC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,kCAAkC;IAClC,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA,uBAAuB;;AAEvB;GACG;IACC,sBAAsB;GACvB;IACC;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,mBAAmB;QACnB,UAAU;QACV,UAAU;MACZ;MACA;QACE,cAAc;QACd,2BAA2B;IAC/B;EACF","sourcesContent":["footer{\n    position: fixed;\n    width: Calc(100vw - var(--sidebar-width));\n    height: var(--footer-height);\n    bottom: 0;\n    right: 0;\n    z-index: 9;\n    background-color: var(--background-colour);\n    box-shadow: 0px -2px 18px 2px rgba(0, 0, 0, 0.041);\n    color: var(--footer-text-color);\n}\n.footer-content{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding-top: 7px;\n    font-size: var(--footer-text-size);\n    gap: 0.3em;\n}\n\n.footer-buttons{\n    display: none;\n}\n\n/* Mobile breakpoints */\n\n@media (max-width: 768px) {\n   footer{\n    width: 100vw!important;\n   }\n    .footer-buttons {\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        align-items: center;\n        width: 50%;\n        gap: 0.3em;\n      }\n      .footer-content{\n        font-size: 8px;\n        color: rgba(0, 0, 0, 0.216);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
