// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container{
  width: auto;
  border-radius: 18px;
}
.image-img {
  width: 100%;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/components/Image/Image.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,4BAA4B;AAC9B","sourcesContent":[".image-container{\n  width: auto;\n  border-radius: 18px;\n}\n.image-img {\n  width: 100%;\n  background-repeat: no-repeat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
