// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-text-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1em;
    margin: var(--header-height) 0;
    animation: hometext 1s linear forwards; 
  }

  @keyframes hometext { 
    0% { 
        margin-left: -200px; 
    } 

    30% { 
      margin-left: 50px; 
    } 

    60% { 
      margin-left: 0px;  
    } 
  }`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,gBAAgB;IAChB,8BAA8B;IAC9B,sCAAsC;EACxC;;EAEA;IACE;QACI,mBAAmB;IACvB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".home-text-content{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    line-height: 1em;\n    margin: var(--header-height) 0;\n    animation: hometext 1s linear forwards; \n  }\n\n  @keyframes hometext { \n    0% { \n        margin-left: -200px; \n    } \n\n    30% { \n      margin-left: 50px; \n    } \n\n    60% { \n      margin-left: 0px;  \n    } \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
