// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2em;
    width: 30vw;
}

.portfolio-card-title {}

.opt-text {
    font-size: 9px;
}

.portfolio-card-image {
    max-width: 100%;
    overflow: hidden;
}

@media (max-width: 768px) {
    .portfolio-card {
       width: 80vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/PortfolioCard/PortfolioCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;IACf,WAAW;AACf;;AAEA,uBAAuB;;AAEvB;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;OACG,WAAW;IACd;AACJ","sourcesContent":[".portfolio-card {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    margin-top: 2em;\n    width: 30vw;\n}\n\n.portfolio-card-title {}\n\n.opt-text {\n    font-size: 9px;\n}\n\n.portfolio-card-image {\n    max-width: 100%;\n    overflow: hidden;\n}\n\n@media (max-width: 768px) {\n    .portfolio-card {\n       width: 80vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
