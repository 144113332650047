// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container{
    /* margin: Calc(1.2 * var(--header-height)) 0; */
    margin: var(--header-height) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-image{
    width: 30%;
    margin-top: 3em;
}
.about-container img {
    border-radius: 30%;
    }

    @media (max-width: 768px){
        .about-image{
            width: 57%;
        }
    }`, "",{"version":3,"sources":["webpack://./src/pages/About/About.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB;;IAEA;QACI;YACI,UAAU;QACd;IACJ","sourcesContent":[".about-container{\n    /* margin: Calc(1.2 * var(--header-height)) 0; */\n    margin: var(--header-height) 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.about-image{\n    width: 30%;\n    margin-top: 3em;\n}\n.about-container img {\n    border-radius: 30%;\n    }\n\n    @media (max-width: 768px){\n        .about-image{\n            width: 57%;\n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
