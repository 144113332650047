// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.footer-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  color: rgb(44, 44, 44);
  display: flex;
  position: relative;
  gap: 5px;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  position: relative;
  font-size: 16px;
}

.footer-button:not(:active):hover,
.footer-button:focus {
  background-color: rgb(178, 178, 178);
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/footerIconButton/FooterIconButton.css"],"names":[],"mappings":";AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;EAEE,oCAAoC;AACtC","sourcesContent":["\n.footer-button {\n  background-color: transparent;\n  border: none;\n  padding: 5px;\n  color: rgb(44, 44, 44);\n  display: flex;\n  position: relative;\n  gap: 5px;\n  cursor: pointer;\n  border-radius: 4px;\n  align-items: center;\n  position: relative;\n  font-size: 16px;\n}\n\n.footer-button:not(:active):hover,\n.footer-button:focus {\n  background-color: rgb(178, 178, 178);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
