// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-img {
  width: auto;
  height: 65px;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,4BAA4B;AAC9B","sourcesContent":[".logo-img {\n  width: auto;\n  height: 65px;\n  background-repeat: no-repeat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
