// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sidebar {
    position: fixed;
    width: var(--sidebar-width);
    padding-top: 20px;
    z-index: 99;
    height: 100vh;
    bottom: 0;
    left: 0;
    box-shadow: 0px 20px 22px 4px rgba(0, 0, 0, 0.085);
    border-right: 1px solid rgb(233, 236, 239);
    background-color: var(--sidebar-colour); 
    padding-bottom: Calc(20px + var(--footer-height));
  }
  .sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: Calc(2 * var(--header-height));
   
  }

  .error{
    width: 80%;
    color: blue;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    .sidebar.open {
      left: 0;
      transition: left ease-in 0.3s;
      box-shadow: 0px 20px 20px 4px rgba(0, 0, 0, 0.085);
    }
    .sidebar {
      left: calc(var(--sidebar-width) * -1);
      transition: left ease-in 0.3s;
      box-shadow: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/layout/sidebar/Sidebar.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,2BAA2B;IAC3B,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,SAAS;IACT,OAAO;IACP,kDAAkD;IAClD,0CAA0C;IAC1C,uCAAuC;IACvC,iDAAiD;EACnD;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,2CAA2C;;EAE7C;;EAEA;IACE,UAAU;IACV,WAAW;IACX,eAAe;EACjB;;EAEA;IACE;MACE,OAAO;MACP,6BAA6B;MAC7B,kDAAkD;IACpD;IACA;MACE,qCAAqC;MACrC,6BAA6B;MAC7B,gBAAgB;IAClB;AACJ","sourcesContent":["\n.sidebar {\n    position: fixed;\n    width: var(--sidebar-width);\n    padding-top: 20px;\n    z-index: 99;\n    height: 100vh;\n    bottom: 0;\n    left: 0;\n    box-shadow: 0px 20px 22px 4px rgba(0, 0, 0, 0.085);\n    border-right: 1px solid rgb(233, 236, 239);\n    background-color: var(--sidebar-colour); \n    padding-bottom: Calc(20px + var(--footer-height));\n  }\n  .sidebar-content {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n    padding-top: Calc(2 * var(--header-height));\n   \n  }\n\n  .error{\n    width: 80%;\n    color: blue;\n    font-size: 14px;\n  }\n\n  @media (max-width: 768px) {\n    .sidebar.open {\n      left: 0;\n      transition: left ease-in 0.3s;\n      box-shadow: 0px 20px 20px 4px rgba(0, 0, 0, 0.085);\n    }\n    .sidebar {\n      left: calc(var(--sidebar-width) * -1);\n      transition: left ease-in 0.3s;\n      box-shadow: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
