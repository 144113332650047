// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sidebar-button-container {
  display: flex;
  flex-direction: column;
  width: Calc(var(--sidebar-width) - 15px);
  background-color: rgb(206, 206, 206);
  justify-content: center;
  border-radius: 5px;
  margin-top: 0.4em;
}

.sidebar-button {
  background-color: transparent;
  border: none;
  padding: 20px;
  color: rgb(44, 44, 44);
  display: flex;
  position: relative;
  gap: 5px;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  position: relative;
}

.sidebar-button:not(:active):hover,
.sidebar-button:focus {
  background-color: rgb(178, 178, 178);
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/sidebar/SidebarButton.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sBAAsB;EACtB,wCAAwC;EACxC,oCAAoC;EACpC,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;;EAEE,oCAAoC;AACtC","sourcesContent":["\n.sidebar-button-container {\n  display: flex;\n  flex-direction: column;\n  width: Calc(var(--sidebar-width) - 15px);\n  background-color: rgb(206, 206, 206);\n  justify-content: center;\n  border-radius: 5px;\n  margin-top: 0.4em;\n}\n\n.sidebar-button {\n  background-color: transparent;\n  border: none;\n  padding: 20px;\n  color: rgb(44, 44, 44);\n  display: flex;\n  position: relative;\n  gap: 5px;\n  cursor: pointer;\n  border-radius: 4px;\n  align-items: center;\n  position: relative;\n}\n\n.sidebar-button:not(:active):hover,\n.sidebar-button:focus {\n  background-color: rgb(178, 178, 178);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
