// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-wrapper-container {
    height: 100vh;
    width: 100%;
    position: relative;
  }

.layout-content-container {
    position: absolute;
    width: Calc(100vw - var(--sidebar-width));
    /* width: 100vw; */
    margin-left: var(--sidebar-width);
    /* height: Calc(100vh + 2*var(--header-height)); */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 3em 0; */
  }

  .logged-out{
    width: 100vw!important;
  }
  

/* Mobile breakpoints */


  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2){
    .layout-content-container {
      width: 100vw;
      margin-left: 0;
    }
}


  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .layout-content-container {
      margin-left: var(--sidebar-width);
      }
  }
  /* @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1180px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .layout-content-container {
      margin-left: 20px;
      }
  } */`, "",{"version":3,"sources":["webpack://./src/layout/Layout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;EACpB;;AAEF;IACI,kBAAkB;IAClB,yCAAyC;IACzC,kBAAkB;IAClB,iCAAiC;IACjC,kDAAkD;IAClD,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;;AAGF,uBAAuB;;;EAGrB;;;;IAIE;MACE,YAAY;MACZ,cAAc;IAChB;AACJ;;;EAGE;;;;;IAKE;MACE,iCAAiC;MACjC;EACJ;EACA;;;;;;;;KAQG","sourcesContent":[".layout-wrapper-container {\n    height: 100vh;\n    width: 100%;\n    position: relative;\n  }\n\n.layout-content-container {\n    position: absolute;\n    width: Calc(100vw - var(--sidebar-width));\n    /* width: 100vw; */\n    margin-left: var(--sidebar-width);\n    /* height: Calc(100vh + 2*var(--header-height)); */\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    /* margin: 3em 0; */\n  }\n\n  .logged-out{\n    width: 100vw!important;\n  }\n  \n\n/* Mobile breakpoints */\n\n\n  @media only screen \n  and (min-device-width: 320px) \n  and (max-device-width: 480px)\n  and (-webkit-min-device-pixel-ratio: 2){\n    .layout-content-container {\n      width: 100vw;\n      margin-left: 0;\n    }\n}\n\n\n  @media only screen \n  and (min-device-width: 768px) \n  and (max-device-width: 1024px) \n  and (orientation: portrait) \n  and (-webkit-min-device-pixel-ratio: 2) {\n    .layout-content-container {\n      margin-left: var(--sidebar-width);\n      }\n  }\n  /* @media only screen \n  and (min-device-width: 768px) \n  and (max-device-width: 1180px) \n  and (orientation: landscape) \n  and (-webkit-min-device-pixel-ratio: 1) {\n    .layout-content-container {\n      margin-left: 20px;\n      }\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
