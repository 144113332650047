// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio{
    margin: var(--header-height) 0;
}

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
}

@media (max-width: 768px) {
    .portfolio-grid {
        grid-template-columns: 1fr;
        gap: 3em;
        margin-bottom: 13em;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Portfolio/Portfolio.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,QAAQ;AACZ;;AAEA;IACI;QACI,0BAA0B;QAC1B,QAAQ;QACR,mBAAmB;IACvB;AACJ","sourcesContent":[".portfolio{\n    margin: var(--header-height) 0;\n}\n\n.portfolio-grid {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 2em;\n}\n\n@media (max-width: 768px) {\n    .portfolio-grid {\n        grid-template-columns: 1fr;\n        gap: 3em;\n        margin-bottom: 13em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
