// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  width: 100%;
  position: fixed;
  background-color: var(--header-colour);
  z-index: 999;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.085);
  border-bottom: 1px solid rgb(233 236 239 / 34%)
}

.header-left {
  margin-left: 2em;
  margin-right: auto;
}

.header-right {
  margin-right: 5em;
  margin-left: auto;
  color: rgb(0, 0, 0);
  max-width: 40%;
}

/* Mobile nav icon */
.burger-close {
  animation: spin-in 0.5s ease-in;
  color: var(--mobile-menu-colour);
}

.burger-open {
  animation: spin-out 0.5s ease-in;
  color: var(--mobile-menu-colour);
}

button {
  padding: 0 1em;
}
/* Animations */
@keyframes spin-in {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes spin-out {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/* Mobile breakpoints */

@media (max-width: 768px) {
  .mobile-nav {
    cursor: pointer;
    margin-left: 1em;
  }
}

@media (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/header/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,4BAA4B;EAC5B,WAAW;EACX,eAAe;EACf,sCAAsC;EACtC,YAAY;EACZ,6CAA6C;EAC7C;AACF;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA,oBAAoB;AACpB;EACE,+BAA+B;EAC/B,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;EAChC,gCAAgC;AAClC;;AAEA;EACE,cAAc;AAChB;AACA,eAAe;AACf;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,yBAAyB;EAC3B;;EAEA;IACE,uBAAuB;EACzB;AACF;;AAEA,uBAAuB;;AAEvB;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".header-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  height: var(--header-height);\n  width: 100%;\n  position: fixed;\n  background-color: var(--header-colour);\n  z-index: 999;\n  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.085);\n  border-bottom: 1px solid rgb(233 236 239 / 34%)\n}\n\n.header-left {\n  margin-left: 2em;\n  margin-right: auto;\n}\n\n.header-right {\n  margin-right: 5em;\n  margin-left: auto;\n  color: rgb(0, 0, 0);\n  max-width: 40%;\n}\n\n/* Mobile nav icon */\n.burger-close {\n  animation: spin-in 0.5s ease-in;\n  color: var(--mobile-menu-colour);\n}\n\n.burger-open {\n  animation: spin-out 0.5s ease-in;\n  color: var(--mobile-menu-colour);\n}\n\nbutton {\n  padding: 0 1em;\n}\n/* Animations */\n@keyframes spin-in {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(180deg);\n  }\n}\n\n@keyframes spin-out {\n  0% {\n    transform: rotate(180deg);\n  }\n\n  100% {\n    transform: rotate(0deg);\n  }\n}\n\n/* Mobile breakpoints */\n\n@media (max-width: 768px) {\n  .mobile-nav {\n    cursor: pointer;\n    margin-left: 1em;\n  }\n}\n\n@media (min-width: 768px) {\n  .mobile-nav {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
